import React from "react";

const AboutContent = () => {
  return (
    <div>
      {" "}
      <div className="px-8 pb-12 m-auto max-w-7xl pt-7">
        <h2 className="mb-2 text-2xl font-bold mt-28 md:mt-16 text-black-light">
          Who is DealerSys
        </h2>

        <p>
          DealerSys is a subsidiary of ACS Cloud Partners, one of the largest
          telecommunications and software master agencies (distribution
          channels) in the country. We offer customized technology solutions
          from hundreds of providers with guaranteed best service, support and
          pricing.
        </p>
        <p>
          We assess a client’s issues and needs and then make recommendations of
          the best technologies that will enable them to thrive. We base our
          client recommendations on the experiences of all provider/client
          relationships within the master agency, so we know (based on
          experiences) that the provider and solution are the right fit.
        </p>

        <h2 className="mt-10 mb-2 text-2xl font-bold text-black-light">
          The Difference
        </h2>
        <p>
          When a company goes directly with a provider through DealerSys it has
          the collective power of our master agency who bills $100k-3M monthly
          with that same provider. We offer the best possible customer
          experience from assessment to signature, transition, implementation,
          and continued support throughout the life of the partnership.
        </p>
        <p>
          We are your security blanket to ensure a seamless transition and
          successful experience with new technologies.
        </p>
      </div>
    </div>
  );
};

export default AboutContent;
