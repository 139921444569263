import React from "react";
import Index from "../views/Developers/Index";
import Layout from "../components/Layout";

function Developers({ location }) {
  return (
    <div>
      <Layout location={location}>
        <Index location={location} />
      </Layout>
    </div>
  );
}

export default Developers;
