import React from "react";
import Header from "./header";
import Speak from "./../../components/Speak";
import AboutContent from "./AboutContent";
import Trusted from "./../Home/Trusted";
function Index({location}) {
  return (
    <div>
      {/* <Header /> */}
      <AboutContent />
      <div className="lg:pt-20 md:pt-12 pt-8">
        <Trusted />
      </div>
      <Speak  location={location}/>
    </div>
  );
}

export default Index;
